import Process1 from '../../assets/image/saasModern/process-1.svg';
import Process2 from '../../assets/image/saasModern/process-2.svg';
import Process3 from '../../assets/image/saasModern/process-3.svg';

import FeatureIcon1 from '../../assets/image/saasModern/icon-1.png';
import FeatureIcon2 from '../../assets/image/saasModern/icon-2.png';
import FeatureIcon3 from '../../assets/image/saasModern/icon-3.png';
import FeatureIcon4 from '../../assets/image/saasModern/icon-4.png';
import FeatureIcon5 from '../../assets/image/saasModern/icon-5.png';
import FeatureIcon6 from '../../assets/image/saasModern/icon-6.png';

import Screenshot1 from '../../assets/image/saasModern/ocr-demo.png';
import Screenshot2 from '../../assets/image/saasModern/chat-on-doc.png';
import MultiBotScreen from '../../assets/image/saasModern/multi-bot.png';
import CollaborateScreen from '../../assets/image/saasModern/collaborate-demo.png';

import AuthorOne from '../../assets/image/saasModern/author-1.jpg';
import AuthorTwo from '../../assets/image/saasModern/author-2.jpg';
import AuthorThree from '../../assets/image/saasModern/author-3.jpg';

import { ic_monetization_on } from 'react-icons-kit/md/ic_monetization_on';
import { ic_settings } from 'react-icons-kit/md/ic_settings';
import { pieChart } from 'react-icons-kit/icomoon/pieChart';
import { briefcase } from 'react-icons-kit/fa/briefcase';

export const MENU_ITEMS = [
  // {
  //   label: 'HOME',
  //   intl: 'Home',
  //   path: '../../',
  //   offset: '0',
  //   location: '/',
  // },
  /*  {
    label: "Changelog",
    path: "../../changelog",
    offset: "0",
    intl: "Changelog",
  }, */
  // {
  //   label: 'About',
  //   path: '../../about',
  //   offset: '0',
  //   intl: 'About',
  // },
  // {
  //   label: 'Widget',
  //   path: '../../chatbot-widget',
  //   offset: '0',
  //   intl: 'Widget',
  //   location: '/chatbot-widget',
  // },
  {
    label: 'Pricing',
    path: '../../pricing',
    location: '/pricing',
    offset: '0',
    intl: { 'en-us': 'Pricing', 'fr-fr': 'Tarification' },
  },

  {
    label: 'Blog',
    path: '../../blog',
    location: '/blog',
    offset: '0',
    intl: { 'en-us': 'Blog', 'fr-fr': 'Blog' },
  },
  // {
  //   label: "Testimonial",
  //   path: "#testimonial_section",
  //   offset: "0",
  // },
  // {
  //   label: "FAQ",
  //   path: "#faq_section",
  //   offset: "0",
  // },
];

export const PROCESS_ITEMS = [
  {
    image: Process1,
    title:
      'Boostez votre productivité grâce à nos fonctionnalités multi-IA révolutionnaires',
    description:
      "Notre plateforme propulsée par des assistants multi-IA vous offre un arsenal d'outils puissants pour booster votre productivité. Allant des réponses intelligentes à l’extraction de vos données, passant par la génération d’images.",
  },
  {
    image: Process2,
    title:
      'Swiftask, la plateforme ultime conçue pour propulser votre productivité à de nouveaux sommets.',
    description:
      'Découvrez dès maintenant comment les fonctionnalités innovantes de Swiftask peuvent transformer votre façon de travailler et vous aider à atteindre vos objectifs avec une efficacité optimale.',
  },
  {
    image: Process3,
    title: 'Document IA (Google)',
    description:
      'Gagnez du temps en évitant la saisie manuelle de vos données. Avec Swiftask, à travers Document AI, vous pouvez extraire vos données pertinentes à partir de divers types de documents (factures, CV, contrats, reçus, etc.), et par sa fonction OCR, convertir les textes de vos images en texte éditable, pour accélérer encore plus vos flux de travail. Les textes générés pourront par la suite être traités avec ChatGPT pour une gestion encore plus efficace.',
  },
  {
    image: Process1,
    title: 'Scrapio',
    description:
      'En tant que bot, Scrapio vous permet de collecter des données à partir de différentes sources en ligne. Il vous aide à récupérer automatiquement des informations pertinentes pour vos tâches et vos projets en cours, éliminant ainsi la nécessité de recherches manuelles fastidieuses.Classez ensuite vos données scrapées selon vos préférences (par titre, par téléphone, par email,etc.)',
  },
  {
    image: Process1,
    title: 'ChatGPT',
    description:
      'Votre assistant personnalisé pour vous répondre, vous guider et vous offrir des recommandations adaptées à vos besoins spécifiques. Un canal de discussion instantanée vous facilitant la communication entre collaborateurs.',
  },
  {
    image: Process1,
    title: 'Dall·E',
    description:
      'Décrivez vos tâches, vos projets ou vos idées par le biais du texte, Swiftask, grâce à Dall·E, vous générera des visuels pertinents et personnalisés selon vos préférences, pour vous aider à transmettre vos informations de manière plus claire et plus concise.',
  },
];

export const MONTHLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'pricing.basics.title',
    description: 'pricing.basics.description',
    price: '$0',
    priceLabel: 'pricing.basics.priceLabel',
    buttonLabel: 'pricing.basics.buttonLabel',
    url: 'https://app.swiftask.ai',
    listItems: [
      {
        content: 'pricing.basics.features.chatgpt',
      },
      {
        content: 'pricing.basics.features.ocr',
      },
      {
        content: 'pricing.basics.features.dalle',
      },
      {
        content: 'pricing.basics.features.promptLibrary',
      },
      {
        content: 'pricing.basics.features.chatOnPdf',
      },
      {
        content: 'pricing.basics.features.projectManagement',
      },

      {
        content: 'pricing.basics.features.speechToText',
      },
    ],
  },
  {
    freePlan: false,
    name: 'pricing.pro.title',
    description: 'pricing.pro.description',
    price: '$5',
    priceLabel: 'pricing.pro.priceLabel',
    buttonLabel: 'pricing.pro.buttonLabel',
    url: 'https://app.swiftask.ai',
    listItems: [
      {
        content: 'pricing.pro.features.chatgpt',
      },
      {
        content: 'pricing.pro.features.ocr',
      },
      {
        content: 'pricing.pro.features.dalle',
      },
      {
        content: 'pricing.pro.features.promptLibrary',
      },
      {
        content: 'pricing.pro.features.chatOnPdf',
      },
      {
        content: 'pricing.pro.features.projectManagement',
      },

      {
        content: 'pricing.pro.features.speechToText',
      },
    ],
  },
  {
    freePlan: false,
    entrePrisePlan: true,
    name: 'pricing.enterprise.title',
    description: 'pricing.enterprise.description',
    price: '$0',
    priceLabel: 'pricing.enterprise.priceLabel',
    buttonLabel: 'pricing.enterprise.buttonLabel',
    url: 'https://app.swiftask.ai',
    contactUsUrl: 'https://calendly.com/swiftask/demo',
    listItems: [
      {
        content: 'pricing.enterprise.features.everythingInPro',
      },

      {
        content: 'pricing.enterprise.features.storage',
      },
      {
        content: 'pricing.enterprise.features.support',
      },
      // sso
      {
        content: 'pricing.enterprise.features.sso',
      },
      // Training
      {
        content: 'pricing.enterprise.features.training',
      },
      // Chat on private data
      {
        content: 'pricing.enterprise.features.chatOnPrivateData',
      },
    ],
  },
  // {
  //   name: "Business Account",
  //   description: "For Mediums teams or group who need to build website ",
  //   price: "$9.87",
  //   priceLabel: "Per month & subscription yearly",
  //   buttonLabel: "START FREE TRIAL",
  //   url: "#",
  //   listItems: [
  //     {
  //       content: "Drag & Drop Builder",
  //     },
  //     {
  //       content: "1,000s of Templates Ready",
  //     },
  //     {
  //       content: "Blog Tools",
  //     },
  //     {
  //       content: "eCommerce Store ",
  //     },
  //     {
  //       content: "30+ Webmaster Tools",
  //     },
  //   ],
  // },
  // {
  //   name: "Premium Account",
  //   description: "For Large teams or group who need to build website ",
  //   price: "$12.98",
  //   priceLabel: "Per month & subscription yearly",
  //   buttonLabel: "START FREE TRIAL",
  //   url: "#",
  //   listItems: [
  //     {
  //       content: "Drag & Drop Builder",
  //     },
  //     {
  //       content: "1,000s of Templates Ready",
  //     },
  //     {
  //       content: "Blog Tools",
  //     },
  //     {
  //       content: "eCommerce Store ",
  //     },
  //     {
  //       content: "30+ Webmaster Tools",
  //     },
  //   ],
  // },
];

export const YEARLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    description: 'For a single client or team who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'CREATE FREE ACCOUNT',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Business Account',
    description: 'For Small teams or group who need to build website ',
    price: '$6.00',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Unlimited secure storage',
      },
      {
        content: '2,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: '24/7 phone support',
      },
      {
        content: '50+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$9.99',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '3,000s of Templates Ready',
      },
      {
        content: 'Advanced branding',
      },
      {
        content: 'Knowledge base support',
      },
      {
        content: '80+ Webmaster Tools',
      },
    ],
  },
];

export const FAQ_DATA = [
  {
    expend: true,
    title: 'How to contact with Customer Service?',
    description:
      'Our Customer Experience Team is available 7 days a week and we offer 2 ways to get in contact.Email and Chat . We try to reply quickly, so you need not to wait too long for a response!. ',
  },
  {
    title: 'App installation failed, how to update system information?',
    description:
      'Please read the documentation carefully . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum . ',
  },
  {
    title: 'Website reponse taking time, how to improve?',
    description:
      'At first, Please check your internet connection . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum .',
  },
  {
    title: 'New update fixed all bug and issues?',
    description:
      'We are giving the update of this theme continuously . You will receive an email Notification when we push an update. Always try to be updated with us .',
  },
];

export const FOOTER_WIDGET = [
  {
    title: { 'en-us': 'About Us', 'fr-fr': 'À propos de nous' },
    menuItems: [
      // {
      //   url: "#",
      //   text: "Support Center",
      // },
      {
        url: 'https://discord.gg/TpxmW8RbZa',
        text: 
        { 'en-us': 'Discord', 'fr-fr': 'Discord' },
        blank: true,
      },
      {
        url: 'https://twitter.com/swiftask_ai',
        text: { 'en-us': 'X (Twitter)', 'fr-fr': 'X (Twitter)' },
        blank: true,
      },
      {
        url: 'https://www.facebook.com/swiftask',
        text: { 'en-us': 'Facebook', 'fr-fr': 'Facebook' },
        blank: true,
      },
      {
        url: 'https://www.linkedin.com/company/swiftask/',
        text: { 'en-us': 'Linkedin', 'fr-fr': 'Linkedin' },
        blank: true,
      },
      // {
      //   url: "#",
      //   text: "Copyright",
      // },
      // {
      //   url: "#",
      //   text: "Popular Campaign",
      // },
    ],
  },
  {
    title: { 'en-us': 'Our Information', 'fr-fr': 'Nos informations' },
    menuItems: [
      {
        url: '/privacy.html',
        text: {
          'en-us': 'Privacy Policy',
          'fr-fr': 'Politique de confidentialité',
        },
        blank: true,
      },
      {
        url: 'https://www.swiftask.ai/tos.html',
        text: {
          'en-us': 'Terms & Conditions',
          'fr-fr': 'Conditions générales',
        },
        blank: true,
      },
    ],
  },
];
 